import { useCallback, useEffect, useRef } from "react";
import FALLBACK from "@/common/images/icons/image_icon.svg";
import "./Image.scss";

interface ImageProps {
  className?: string;
  src?: string | null;
  alt?: string;
  width?: string;
  height?: string;
  fallback?: string;
  onFailure?: (url: string) => void;
  lazyLoad?: boolean;
}

export const Image = ({
  className = "",
  src,
  alt,
  width,
  height,
  fallback = FALLBACK,
  onFailure
}: ImageProps) => {
  const ref = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const options = {
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (ref.current) {
            ref.current.src = src || fallback;
          }

          observer.disconnect();
        }
      });
    }, options);

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref]);

  const onError = useCallback(() => {
    console.warn(`Error loading image ${src} -- showing fallback.`);
    onFailure?.(src || fallback);
    if (ref.current) {
      ref.current.src = fallback;
      ref.current.alt = alt ?? "";
      ref.current.style.opacity = "1";
    }
  }, []);

  const onLoaded = useCallback(() => {
    if (ref.current) {
      ref.current.alt = alt ?? "";
      ref.current.style.opacity = "1";
    }
  }, []);

  return (
    <img
      ref={ref}
      className={className}
      alt=""
      style={{
        opacity: 0,
        transition: "opacity 0.1s ease-in-out",
      }}
      width={width}
      height={height}
      onError={onError}
      onLoad={onLoaded}
    />
  );
};
