import { Navigate, Route, Routes } from "react-router-dom";
import { lazy, Suspense } from "react";

const AccountSettingsPage = lazy(() => import("@/pages/account-settings-page"));
const AnalyticsPage = lazy(() => import("@/pages/analytics-page"));
const AuthorizePage = lazy(() => import("@/pages/authorize-page"));
const CategoriesPage = lazy(() => import("@/pages/categories-page"));
const CollectionPage = lazy(() => import("@/pages/collection-page"));
const CollectionsPage = lazy(() => import("@/pages/collections-page"));
const EmbedPage = lazy(() => import("@/pages/embed-page"));
const ExperimentsPage = lazy(() => import("@/pages/experiments-page"));
const ExploreCreatorsPage = lazy(() => import("@/pages/explore-creators-page"));
const ExploreGifsPage = lazy(() => import("@/pages/explore-gifs-page"));
const ExploreImagesPage = lazy(() => import("@/pages/explore-images-page"));
const ExploreNichesPage = lazy(() => import("@/pages/explore-niches-page"));
const ExplorePage = lazy(() => import("@/pages/explore-page"));
const FavouritesPage = lazy(() => import("@/pages/favourites-page"));
const FreetypeSearchPage = lazy(() => import("@/pages/freetype-search-page"));
const HomePage = lazy(() => import("@/pages/home-page"));
const ManageAdSlotsPage = lazy(() => import("@/pages/manage-ad-slots-page"));
const ManagePage = lazy(() => import("@/pages/manage-page"));
const MyNichesPage = lazy(() => import("@/pages/my-niches-page"));
const NichePage = lazy(() => import("@/pages/niche-page"));
const ProfileSettingsPage = lazy(() => import("@/pages/profile-settings-page"));
const SearchPage = lazy(() => import("@/pages/search-page"));
const SearchWarningPage = lazy(() => import("@/pages/search-warning-page"));
const SignInPage = lazy(() => import("@/pages/signin-page"));
const SignUpPage = lazy(() => import("@/pages/signup-page"));
const StaticPages = lazy(() => import("@/pages/static-pages"));
const SubscriptionPages = lazy(() => import("@/pages/subscription-pages"));
const SudoPage = lazy(() => import("@/pages/sudo-page"));
const TagPage = lazy(() => import("@/pages/tag-page"));
const GeoBlockedPage = lazy(() => import("@/pages/geo-blocked-page"));
const UnsubscribedPage = lazy(() => import("@/pages/unsubscribed-page"));
const UploadPage = lazy(() => import("@/pages/upload-page"));
const UserPage = lazy(() => import("@/pages/user-page"));
const VerifyPage = lazy(() => import("@/pages/verify-page"));
const WatchPage = lazy(() => import("@/pages/watch-page"));
const PWAInfoPage = lazy(() => import("@/pages/pwa-info-page"));

import WithAuthRequired from "./WithAuthRequired";
import { routes } from "@/common/utils/routes";
import { Loader } from "@/components/UI/Loader";
import { WithTimer } from "./UI/WithTimer";

const RoutesComponents = () => {
  return (
    <Suspense fallback={<WithTimer waitTime={2000}><Loader /></WithTimer>}>
      <Routes>
        <Route path={routes.dashboard()} element={<WithAuthRequired><AnalyticsPage /></WithAuthRequired>} />
        <Route path={routes.watch(":id")} element={<WatchPage />} />
        <Route path={routes.embed(":id")} element={<EmbedPage />} />
        <Route path={routes.home()} element={<HomePage />}>
          <Route path={":videoId"} element={<HomePage />} />
          <Route path={""} element={<HomePage />} />
        </Route>
        <Route path={routes.explore()} element={<ExplorePage />} />
        <Route path={routes.exploreCreators()} element={<ExploreCreatorsPage />} />
        <Route path={routes.exploreImages()} element={<ExploreImagesPage />} />
        <Route path={routes.exploreTop()} element={<ExploreGifsPage />} />
        <Route path={routes.niches()} element={<ExploreNichesPage />} />
        <Route path={routes.myNiches()} element={<WithAuthRequired><MyNichesPage /></WithAuthRequired>} />
        <Route path={routes.favourites()} element={<FavouritesPage />} />

        <Route path={routes.niche(":id")} element={<NichePage />} />
        <Route path={routes.user(":id")} element={<UserPage />} />
        <Route path={routes.userCollections(":userId")} element={<CollectionsPage />} />
        <Route path={routes.collection(":userId", ":id")} element={<CollectionPage />} />
        <Route path={routes.following()} element={<WithAuthRequired><SubscriptionPages type={routes.following()} /></WithAuthRequired>} />
        <Route path={routes.followers()} element={<WithAuthRequired><SubscriptionPages type={routes.followers()} /></WithAuthRequired>} />
        <Route path={routes.singleTag(":tag")} element={<TagPage />} />
        <Route path={routes.search()} element={<SearchPage />} />
        <Route path={routes.freetypeSearch()} element={<FreetypeSearchPage />} />
        <Route path={routes.trendingTags()} element={<CategoriesPage />} />
        <Route path={routes.trendingTagsA(":letter")} element={<CategoriesPage />} />
        <Route path={routes.create()} element={<UploadPage />} />
        <Route path={routes.manageProfile()} element={<WithAuthRequired><ManagePage /></WithAuthRequired>} />
        <Route path={routes.accountSettings()} element={<WithAuthRequired><AccountSettingsPage /></WithAuthRequired>} />
        <Route path={routes.profileSettings()} element={<WithAuthRequired><ProfileSettingsPage /></WithAuthRequired>} />
        <Route path={routes.verify()} element={<WithAuthRequired><VerifyPage /></WithAuthRequired>} />
        <Route path={routes.unsubscribed()} element={<UnsubscribedPage />} />
        <Route path={routes.authorize()} element={<WithAuthRequired><AuthorizePage /></WithAuthRequired>} />
        <Route path={routes.experiments()} element={<ExperimentsPage />} />
        <Route path={routes.sudo()} element={<WithAuthRequired><SudoPage /></WithAuthRequired>} />
        <Route path={routes.manageAdSlots()} element={<WithAuthRequired><ManageAdSlotsPage /></WithAuthRequired>} />

        <Route path={routes.guidelines()} element={<StaticPages type={routes.guidelines()} />} />
        <Route path={routes.faq()} element={<StaticPages type={routes.faq()} />} />
        <Route path={routes.terms()} element={<StaticPages type={routes.terms()} />} />
        <Route path={routes.statement2257()} element={<StaticPages type={routes.statement2257()} />} />
        <Route path={routes.dmca()} element={<StaticPages type={routes.dmca()} />} />
        <Route path={routes.privacyPolicy()} element={<StaticPages type={routes.privacyPolicy()} />} />

        <Route path={routes.geoBlockedPage(":location")} element={<GeoBlockedPage />} />
        <Route path={routes.searchWarningPage()} element={<SearchWarningPage />} />
        <Route path={routes.app()} element={<PWAInfoPage />} />

        <Route path={routes.userSignIn()} element={<SignInPage />} />
        <Route path={routes.userSignUp()} element={<SignUpPage />} />

        {/* Prevent partytown issue with requested wrong page in Firefox */}
        <Route path={routes.partyTown()} element={<></>} />

        <Route path="*" element={<Navigate to={routes.home()} />} />
      </Routes>
    </Suspense>
  );
};

export default RoutesComponents;
